import React, { useEffect, useState } from "react";

import "./main.css";

import banner1 from "../assets/img/banner/1.png";
import banner2 from "../assets/img/banner/2.png";
import banner7 from "../assets/img/banner/7.jpg";
import banner10 from "../assets/img/banner/10.jpg";

import imageshapemap from "../assets/img/shape/map.png";
import imageshape18 from "../assets/img/shape/18.png";

import imageshapeanim1 from "../assets/img/shape/anim-1.png";
import imageshapeanim2 from "../assets/img/shape/anim-2.png";
import imageshapeanim3 from "../assets/img/shape/anim-3.png";
import imageshapeanim4 from "../assets/img/shape/anim-4.png";

//import imageshape4 from "../assets/img/shape/4.png";
import imageshape11 from "../assets/img/shape/11.png";

import imageabout1 from "../assets/img/about/1.png";
import imageabout3 from "../assets/img/about/3.png";
import imageabout5 from "../assets/img/about/5.png";
import imageabout6 from "../assets/img/about/6.png";

import imagelogo1 from "../assets/img/logo/1.png";
import imagelogo2 from "../assets/img/logo/2.png";
import imagelogo7 from "../assets/img/logo/7.png";
import imagelogo4 from "../assets/img/logo/4.png";
import imagelogo5 from "../assets/img/logo/5.png";
import imagelogo6 from "../assets/img/logo/6.png";

import imageteam1 from "../assets/img/team/1.jpg";
import imageteam3 from "../assets/img/team/3.jpg";

import imageservice1 from "../assets/img/services/1.jpg";
import imageservice2 from "../assets/img/services/2.jpg";
import imageservice3 from "../assets/img/services/3.jpg";
import imageservice4 from "../assets/img/services/4.jpg";
import imageservice5 from "../assets/img/services/5.jpg";
import imageservice6 from "../assets/img/services/6.jpg";

import imageillustration5 from "../assets/img/illustration/5.png";

import imageshape7 from "../assets/img/shape/7.png";
import imageshape9 from "../assets/img/shape/9.png";
import imageshape17 from "../assets/img/shape/17.png";
// import logolight from "../assets/img/logo-light.png";

import nazsanlogo from "../assets/img/Nazsan Infotech-logos_transparent.png";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Main() {
  const [isOpen, setIsOpen] = useState(false);

  const ulStyle = {
    height: "300px",
    transition: "height .3s all ease-in-out",
  };

  const [success, setSuccess] = useState(1);
  const [clients, setClients] = useState(1);
  const [bussiness, setBussiness] = useState(1);
  const [locations, setLocation] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (success < 100) {
        setSuccess(success + 1);
      }

      if (clients < 120) {
        setClients(clients + 1);
      }

      if (bussiness < 50) {
        setBussiness(success + 1);
      }

      if (locations < 10) {
        setLocation(clients + 1);
      }
    }, 50);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [success, clients]);

  return (
    <>
    {/* Start Header ============================================ */}
      <Header />
     {/* End Header */}

      {/* Start Banner Area ====================================== */}

      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
        style={{ marginTop: "5.5rem" }}
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src={banner1}
              style={{ filter: "blur(1px)" }}
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-md-block">
              <div class="content">
                <h4 className="text-white">Unlocking Possibilities Daily</h4>
                <h2 className="text-white">
                  <strong>Innovate with </strong>Nazsan Infotech
                </h2>
                <div class="button mt-40">
                  <Link class="btn-animation" to="/contact-us">
                    <i class="fas fa-arrow-right"></i>
                    <span className="text-white">Contact Us</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src={banner2}
              style={{ filter: "blur(1px)" }}
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-md-block">
              <div class="content">
                <h4 className="text-white">Together We Thrive</h4>
                <h2 className="text-white">
                  <strong>Connect. Collaborate.</strong> Succeed.
                </h2>
                <div class="button mt-40">
                  <Link class="btn-animation" to="/contact-us">
                    <i class="fas fa-arrow-right"></i>{" "}
                    <span className="text-white">Contact Us</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Banner */}

      {/* Start About ============================================= */}
      <div class="about-style-one-area default-padding">
        <div class="shape-animated-left">
          <img src={imageshapeanim1} alt="Image Not Found" />
          <img src={imageshapeanim2} alt="Image Not Found" />
        </div>
        <div class="container">
          <div class="row align-center">
            <div class="about-style-one col-xl-6 col-lg-5">
              <div class="h4 sub-heading">Empower Your Business Today</div>
              <h2 class="title mb-25">Achieve More Together</h2>
              <p>
                Empowering success. We understand your challenges, ask the right
                questions, and collaborate to achieve remarkable results.
                Together, we navigate the journey of progress with joy and
                purpose.
              </p>
            </div>
            <div class="about-style-one col-xl-5 offset-xl-1 col-lg-6 offset-lg-1">
              <div class="about-thumb">
                <img
                  class="wow fadeInRight"
                  src={imageabout1}
                  alt="Image Not Found"
                />
                <div class="about-card wow fadeInUp" data-wow-delay="500ms">
                  <ul>
                    <li>
                      <div class="icon">
                        <i class="flaticon-license"></i>
                      </div>
                      <div class="fun-fact">
                        <div class="counter">
                          <div class="timer" data-to="100" data-speed="2000">
                            {success}
                          </div>
                          <div class="operator">%</div>
                        </div>
                        <span class="medium">Success</span>
                      </div>
                    </li>
                    <li>
                      <div class="icon">
                        <i class="flaticon-global"></i>
                      </div>
                      <div class="fun-fact">
                        <div class="counter">
                          <div class="timer" data-to="120" data-speed="2000">
                            {clients}
                          </div>
                          <div class="operator">+</div>
                        </div>
                        <span class="medium">Worldwide Clients</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  class="thumb-shape-bottom wow fadeInDown"
                  data-wow-delay="300ms"
                >
                  <img src={imageshapeanim3} alt="Image Not Found" />
                  <img src={imageshapeanim4} alt="Image Not Found" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About */}

      {/* Start Process */}
      <div class="process-style-one-area text-center default-padding">
        <div class="large-shape">
          <img src={imageshape11} alt="Shape" />
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="site-heading text-center">
                <h4 class="sub-heading">Our Process</h4>
                <h2 class="title">
                  Steps of Recruitment <br /> work process
                </h2>
                <div class="devider"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            {/* Single Item */}
            <div class="col-lg-4">
              <div class="process-style-one active">
                <div class="thumb">
                  <img src={imageabout3} alt="Thumb" />
                  <span>01</span>
                </div>
                <h4>Understanding Your Needs</h4>
                <p>
                  We begin by thoroughly understanding your unique requirements
                  and challenges, ensuring a tailored approach to every project.
                </p>
              </div>
            </div>
            {/* End Single Item */}
            {/* Single Item */}
            <div class="col-lg-4">
              <div class="process-style-one">
                <div class="thumb">
                  <img src={imageabout6} alt="Thumb" />
                  <span>02</span>
                </div>
                <h4>Strategic Planning</h4>
                <p>
                  Next, we develop a meticulous strategy, leveraging our
                  expertise to craft solutions that align with your goals and
                  vision.
                </p>
              </div>
            </div>
            {/* End Single Item */}
            {/* Single Item */}
            <div class="col-lg-4">
              <div class="process-style-one">
                <div class="thumb">
                  <img src={imageabout5} alt="Thumb" />
                  <span>03</span>
                </div>
                <h4>Implementation and Success</h4>
                <p>
                  Finally, we put our strategy into action, delivering results
                  that empower your success, and continuously optimizing for
                  ongoing excellence.
                </p>
              </div>
            </div>
            {/* End Single Item */}
          </div>
        </div>
      </div>
      {/* End Process */}

      {/* Start Why Choose Us */}
      <div
        id="about"
        class="choose-us-style-one-area default-padding text-light"
      >
        <div
          class="cover-bg"
          style={{ backgroundImage: `url(${banner7})` }}
        ></div>
        <div class="shape-left-top">
          <img src={imageshape17} alt="Shape" />
        </div>
        {/*<div class="text-invisible">Consua</div>*/}
        <div class="container">
          <div class="row">
            <div class="col-lg-6 pr-80">
              <div class="choose-us-style-one">
                <h2 class="title mb-35">
                  Empowering Success Through IT Excellence
                </h2>
                <ul class="list-item">
                  <li class="wow fadeInUp">
                    <h4>Mission</h4>
                    <p>
                      To provide exceptional IT consulting services, guiding
                      clients towards growth, security, and digital excellence
                      with expertise, integrity, and dedication.
                    </p>
                  </li>
                  <li class="wow fadeInUp" data-wow-delay="300ms">
                    <h4>Vision</h4>
                    <p>
                      Empowering businesses through innovative solutions and
                      fostering lasting partnerships in the ever-evolving world
                      of technology.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Why Choose Us */}

      {/* Start Partner Area */}
      <div
        style={{ display: "none" }}
        class="partner-style-one-area default-padding"
      >
        <div class="container">
          <div class="row align-center">
            <div class="col-lg-5">
              <div
                class="partner-map"
                style={{ backgroundImage: `url(${imageshapemap})` }}
              >
                <h2
                  class="mask-text"
                  style={{ backgroundImage: `url(${banner10})` }}
                >
                  80
                </h2>
                <h4>Partners in world wide</h4>
              </div>
            </div>
            <div class="col-lg-6 offset-lg-1">
              <div class="partner-items">
                <ul>
                  <li>
                    <img src={imagelogo1} alt="Image Not Found" />
                  </li>
                  <li>
                    <img src={imagelogo2} alt="Image Not Found" />
                  </li>
                  <li>
                    <img src={imagelogo7} alt="Image Not Found" />
                  </li>
                  <li>
                    <img src={imagelogo4} alt="Image Not Found" />
                  </li>
                  <li>
                    <img src={imagelogo5} alt="Image Not Found" />
                  </li>
                  <li>
                    <img src={imagelogo6} alt="Image Not Found" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Partner Area */}

      {/* Start Projects */}
    <div className="text-center mt-5"><h1 className="p-2 text-info">SERVICES</h1></div>
      <div
        id="services"
        class="project-style-one-area default-padding bg-dark bottom-shape-light"
      >
        <div class="container">
          <div class="heading-left text-light">
            <div class="row">
              <div class="col-lg-5">
                <div class="content-left">
                  <h5 class="sub-heading">Our Services</h5>
                  <h2 class="heading">
                    Services That Transform Your Business. Your Success, Our
                    Expertise.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/* Slider main container */}
              <div class="project-style-one-carousel swiper">
                <div
                  id="carouselExampleDark"
                  class="carousel carousel-dark slide"
                  data-bs-ride="carousel"
                  style={{ marginTop: "5.5rem" }}
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      {/* Single Item */}
                      <div class="swiper-slide">
                        <div class="project-style-one">
                          <div
                            class="row align-bottom"
                            style={{ bsgutterx: "0px" }}
                          >
                            <div class="col-lg-7 pr-0 pr-md-15 pr-xs-15 pl-md-15 pl-xs-15">
                              <div class="thumb">
                                <img
                                  src={imageservice1}
                                  alt="Image Not Found"
                                />
                              </div>
                            </div>
                            <div class="col-lg-5 pl-0 pl-md-15 pl-xs-15 pr-md-15 pr-xs-15">
                              <div class="info">
                                <span>Service</span>
                                <h3>
                                  <a>Software Development</a>
                                </h3>
                                <p>
                                  We specialize in crafting custom software
                                  solutions tailored to your business needs,
                                  optimizing efficiency, and elevating user
                                  experiences. Our solutions align seamlessly
                                  with your unique objectives, enhancing
                                  productivity and competitive edge.
                                </p>
                                <Link
                                  class="btn-animation dark mt-10"
                                  to="/contact-us"
                                >
                                  <i class="fas fa-arrow-right"></i>{" "}
                                  <span>Get Quote</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Item */}
                    </div>
                    <div class="carousel-item">
                      {/* Single Item */}
                      <div class="swiper-slide">
                        <div class="project-style-one">
                          <div class="row align-bottom">
                            <div class="col-lg-7 pr-0 pr-md-15 pr-xs-15 pl-md-15 pl-xs-15">
                              <div class="thumb">
                                <img
                                  src={imageservice2}
                                  alt="Image Not FOund"
                                />
                              </div>
                            </div>
                            <div class="col-lg-5 pl-0 pl-md-15 pl-xs-15 pr-md-15 pr-xs-15">
                              <div class="info">
                                <span>Service</span>
                                <h3>
                                  <a>Firewall Development</a>
                                </h3>
                                <p>
                                  Our seasoned team designs and deploys robust
                                  firewalls, bolstering your network security,
                                  safeguarding critical data, and ensuring
                                  compliance with industry standards. We fortify
                                  your digital defenses against emerging cyber
                                  threats.
                                </p>
                                <Link
                                  class="btn-animation dark mt-10"
                                  to="/contact-us"
                                >
                                  <i class="fas fa-arrow-right"></i>{" "}
                                  <span>Get Quote</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Item */}
                    </div>

                    <div class="carousel-item">
                      {/* Single Item */}
                      <div class="swiper-slide">
                        <div class="project-style-one">
                          <div class="row align-bottom">
                            <div class="col-lg-7 pr-0 pr-md-15 pr-xs-15 pl-md-15 pl-xs-15">
                              <div class="thumb">
                                <img
                                  src={imageservice3}
                                  alt="Image Not Found"
                                />
                              </div>
                            </div>
                            <div class="col-lg-5 pl-0 pl-md-15 pl-xs-15 pr-md-15 pr-xs-15">
                              <div class="info">
                                <span>Service</span>
                                <h3>
                                  <a>Network Design and Administration</a>
                                </h3>
                                <p>
                                  We excel in designing and administering
                                  scalable, efficient networks that facilitate
                                  seamless communication and data management.
                                  Our solutions optimize your digital
                                  infrastructure, enhancing performance and
                                  reliability.
                                </p>
                                <Link
                                  class="btn-animation dark mt-10"
                                  to="/contact-us"
                                >
                                  <i class="fas fa-arrow-right"></i>{" "}
                                  <span>Get Quote</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Item */}
                    </div>

                    <div class="carousel-item">
                      {/* Single Item */}
                      <div class="swiper-slide">
                        <div class="project-style-one">
                          <div class="row align-bottom">
                            <div class="col-lg-7 pr-0 pr-md-15 pr-xs-15 pl-md-15 pl-xs-15">
                              <div class="thumb">
                                <img
                                  src={imageservice4}
                                  alt="Image Not Found"
                                />
                              </div>
                            </div>
                            <div class="col-lg-5 pl-0 pl-md-15 pl-xs-15 pr-md-15 pr-xs-15">
                              <div class="info">
                                <span>Service</span>
                                <h3>
                                  <a>Software Testing</a>
                                </h3>
                                <p>
                                  With meticulous testing protocols, we ensure
                                  the reliability and functionality of your
                                  software. Our comprehensive testing approach
                                  guarantees a smooth and problem-free user
                                  experience post-launch, enhancing customer
                                  satisfaction.
                                </p>
                                <Link
                                  class="btn-animation dark mt-10"
                                  to="/contact-us"
                                >
                                  <i class="fas fa-arrow-right"></i>{" "}
                                  <span>Get Quote</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Item */}
                    </div>

                    <div class="carousel-item">
                      {/* Single Item */}
                      <div class="swiper-slide">
                        <div class="project-style-one">
                          <div class="row align-bottom">
                            <div class="col-lg-7 pr-0 pr-md-15 pr-xs-15 pl-md-15 pl-xs-15">
                              <div class="thumb">
                                <img
                                  src={imageservice5}
                                  alt="Image Not Found"
                                />
                              </div>
                            </div>
                            <div class="col-lg-5 pl-0 pl-md-15 pl-xs-15 pr-md-15 pr-xs-15">
                              <div class="info">
                                <span>Service</span>
                                <h3>
                                  <a>Security System Maintenance</a>
                                </h3>
                                <p>
                                  Our proactive maintenance and regular updates
                                  fortify your security systems, safeguarding
                                  your assets against evolving threats and
                                  vulnerabilities. We keep your digital defenses
                                  strong and resilient, ensuring continuous
                                  protection.
                                </p>
                                <Link
                                  class="btn-animation dark mt-10"
                                  to="/contact-us"
                                >
                                  <i class="fas fa-arrow-right"></i>{" "}
                                  <span>Get Quote</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Item */}
                    </div>

                    <div class="carousel-item">
                      {/* Single Item */}
                      <div class="swiper-slide">
                        <div class="project-style-one">
                          <div class="row align-bottom">
                            <div class="col-lg-7 pr-0 pr-md-15 pr-xs-15 pl-md-15 pl-xs-15">
                              <div class="thumb">
                                <img
                                  src={imageservice6}
                                  alt="Image Not FOund"
                                />
                              </div>
                            </div>
                            <div class="col-lg-5 pl-0 pl-md-15 pl-xs-15 pr-md-15 pr-xs-15">
                              <div class="info">
                                <span>Service</span>
                                <h3>
                                  <a>Support Services</a>
                                </h3>
                                <p>
                                  Our responsive IT support minimizes downtime,
                                  maximizing productivity for your organization.
                                  We tackle challenges head-on, ensuring that
                                  your business operations run smoothly and
                                  efficiently, no matter the circumstances.
                                </p>
                                <Link
                                  class="btn-animation dark mt-10"
                                  to="/contact-us"
                                >
                                  <i class="fas fa-arrow-right"></i>{" "}
                                  <span>Get Quote</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Item */}
                    </div>
                  </div>
                </div>

                {/* Navigation */}
                <div class="project-swiper-nav">
                  {/* Pagination */}

                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </button>

                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Projects Area */}

      {/* Start Request Call Back */}
      <div
        class="request-call-back-area text-light default-padding"
        style={{ backgroundImage: `url(${banner1})` }}
      >
        <div class="container">
          <div class="row align-center">
            <div class="col-lg-6">
              <h2 class="title">
                Looking for a First-Class <br /> IT Consultant?
              </h2>
              <Link
                class="btn circle btn-light mt-30 mt-md-15 mt-xs-10 btn-md radius animation"
                to="/contact-us"
              >
                Request a Call back
              </Link>
            </div>
            <div class="col-lg-6 text-end">
              <div class="achivement-counter">
                <ul>
                  <li>
                    <div class="icon">
                      <i class="flaticon-handshake"></i>
                    </div>
                    <div class="fun-fact">
                      <div class="counter">
                        <div class="timer" data-to="50" data-speed="2000">
                          {bussiness}
                        </div>
                        <div class="operator">+</div>
                      </div>
                      <span class="medium">
                        Businesses helped over 30 years
                      </span>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="flaticon-employee"></i>
                    </div>
                    <div class="fun-fact">
                      <div class="counter">
                        <div class="timer" data-to="10" data-speed="2000">
                          {locations}
                        </div>
                        <div class="operator">+</div>
                      </div>
                      <span class="medium">Geogaphic locations</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Request Call Back  */}

{/* Start Testimonials
    ============================================= */}
            <div class="testimonial-style-one-area default-padding">
                <div class="container">
                    <div class="row align-center">
 
                        <div class="col-lg-4">
                            <div class="testimonial-thumb">
                                <div class="thumb-item">
                                    <img src={imageillustration5} alt="illustration" />
                                    <div class="mini-shape">
                                        {/*<img src="assets/img/shape/19.png" alt="illustration">*/}
                                    </div>
                                </div>
                            </div>
                        </div>
 
                        <div class="col-lg-7 offset-lg-1">
                            <div class="testimonial-carousel swiper">
                                <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                                    <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div class="testimonial-style-one">
                                                <div class="item">
                                                    <div class="content">
                                                        <div class="rating">
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                        {/*<h2>Rajesh Verma</h2>*/}
                                                        <p>
                                                            “Exceptional service! Terabite Innovation transformed our business.
                                                            Their software solutions are top-notch, and their support is unmatched.”
                                                        </p>
                                                    </div>
                                                    <div class="provider">
                                                        <i class="flaticon-quote"></i>
                                                        <div class="info">
                                                            <h4>Rajesh Verma</h4>
                                                            {/*<span>Senior Consultant</span>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
 
                                        <div class="carousel-item">
                                            <div class="testimonial-style-one">
                                                <div class="item">
                                                    <div class="content">
                                                        <div class="rating">
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                        {/*<h2>Awesome Business opportunities</h2>*/}
                                                        <p>
                                                            “Impressed with their expertise. The network design and security system
                                                            maintenance have been invaluable for our company. Highly recommended!”
                                                        </p>
                                                    </div>
                                                    <div class="provider">
                                                        <i class="flaticon-quote"></i>
                                                        <div class="info">
                                                            <h4>Priya Patel</h4>
                                                            {/*<span>Marketing Manager</span>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
 
                                        <div class="carousel-item">
                                            <div class="testimonial-style-one">
                                                <div class="item">
                                                    <div class="content">
                                                        <div class="rating">
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                        {/*<h2>Awesome Business opportunities</h2>*/}
                                                        <p>
                                                            “Outstanding support services! Terabite's team is responsive and
                                                            efficient. They keep our operations running seamlessly. A trusted
                                                            partner.”
                                                        </p>
                                                    </div>
                                                    <div class="provider">
                                                        <i class="flaticon-quote"></i>
                                                        <div class="info">
                                                            <h4>Ananya Sharma</h4>
                                                            {/*<span>Marketing Manager</span>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
 
 
 
                            </div>
                        </div>
 
                    </div>
                </div>
            </div>
            {/* End Testimonails  */}

      {/* Start Footer */}
      <Footer />
      {/* End Footer */}
    </>
  );
}

export default Main;
