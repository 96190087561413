import './App.css';
import Main from './components/pages/main';


import 'bootstrap/dist/css/bootstrap.min.css';

import "./components/assets/css/style.css";
import "./components/assets/css/validnavs.css";
import "./components/assets/css/animate.css";
import "./components/assets/css/elegant-icons.css";
import "./components/assets/css/flaticon-set.css";
import "./components/assets/css/helper.css";
import "./components/assets/css/magnific-popup.css";
import "./components/assets/css/font-awesome.min.css";
import "./components/assets/css/swiper-bundle.min.css";
import "./components/assets/css/themify-icons.css";
import "./components/assets/css/unit-test.css";
import ContactUs from './components/pages/ContactUs';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Main />}/>
      <Route path='/contact-us' element={<ContactUs />}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
