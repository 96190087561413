import Header from "./Header";

import banner1 from '../assets/img/banner/1.png';
import imageshape50 from "../assets/img/shape/50.png";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";

const ContactUs = ()=>{

    const navigate = useNavigate();

    const onSubmit = ()=>{
        alert("Thanks for showing your interest");
        navigate("/contact-us")
    }
    return(
        <>
        <Header />
        <div class="breadcrumb-area bg-cover shadow dark text-center text-light" style={{ backgroundImage: `url(${banner1})`,  marginTop: "5.5rem" }}>
        <div class="breadcrum-shape">
            <img src={imageshape50} alt="Image Not Found" />
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h1>Contact Us</h1>
                    <ul class="breadcrumb">
                        <li><Link to="/"><i class="fas fa-home"></i> Home</Link></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
           {/* <!-- Start Contact Us 
    ============================================= --> */}
    <div class="contact-style-one-area overflow-hidden default-padding">

        <div class="contact-shape">
            <img src="assets/img/shape/37.png" alt="Image Not Found" />
        </div>
       
        <div class="container">
            <div class="row align-center">

                <div class="contact-stye-one col-lg-5 mb-md-50 mb-xs-20">

                    <div class="contact-style-one-info">
                        <h2>Contact Information</h2>
                        <p>Reach out to Nazsan Infotech today. We're here to assist you with any inquiries or information you need. Your success is our priority.</p>
                        <ul>
                            <li class="wow fadeInUp">
                                <div class="icon">
                                    <i class="fas fa-phone-alt"></i>
                                </div>
                                <div class="content">
                                    <h5 class="title">Contact Number</h5>
                                    <a href="tel:+91 85 2761 1670">+91 85 2761 1670</a>
                                </div>
                            </li>
                            <li class="wow fadeInUp" data-wow-delay="300ms">
                                <div class="icon">
                                    <i class="fas fa-map-marker-alt"></i>
                                </div>
                                <div class="info">
                                    <h5 class="title">Our Location</h5>
                                    <p>
                                    Building No. R.25 , Second Floor, Rita Block Shakarpur,
                  Delhi-110092                                    </p>
                                </div>
                            </li>
                            <li class="wow fadeInUp" data-wow-delay="500ms">
                                <div class="icon">
                                    <i class="fas fa-envelope-open-text"></i>
                                </div>
                                <div class="info">
                                    <h5 class="title">Official Email</h5>
                                    <a href="mailto:nazsaninfotech@gmail.com">nazsaninfotech@gmail.com</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="contact-stye-one col-lg-7 pl-60 pl-md-15 pl-xs-15">
                    <div class="contact-form-style-one">
                        <h5 class="sub-title">Have Questions?</h5>
                        <h2 class="heading">Send us a Message</h2>
                        <form onSubmit={onSubmit} class="contact-form contact-form">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input class="form-control" required id="name" name="name" placeholder="Name" type="text" />
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" required id="email" name="email" placeholder="Email*" type="email" />
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input class="form-control" required id="phone" name="phone" placeholder="Phone" type="tel" />
                                        <span class="alert-error"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group comments">
                                        <textarea class="form-control" required id="comments" name="message" placeholder="Tell Us About Project *"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <button type="submit" name="submit" id="submit">
                                        <i class="fa fa-paper-plane"></i> Get in Touch
                                    </button>
                                </div>
                            </div>
                            {/* <!-- Alert Message --> */}
                            <div class="col-lg-12 alert-notification">
                                <div id="message" class="alert-msg"></div>
                            </div>
                        </form>
                    </div>
                </div>

                

            </div>
        </div>
    </div>
    {/* <!-- End Contact --> */}
    {/* <!-- Start Map 
    ============================================= --> */}
    <div class="maps-area bg-gray overflow-hidden">
        <div class="google-maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3501.9015370683796!2d77.27894707409433!3d28.632712984075066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sBuilding%20No.%20R.25%20%2C%20Second%20Floor%2C%20Rita%20Block%20Shakrpur%2C%20Delhi-110092!5e0!3m2!1sen!2sin!4v1707084634826!5m2!1sen!2sin"></iframe>
        </div>
    </div>
    {/* <!-- End Map --> */}

    <Footer />
        </>
    )
}

export default ContactUs;