import imageshape7 from "../assets/img/shape/7.png";
import imageshape9 from "../assets/img/shape/9.png";
import nazsanlogo from "../assets/img/Nazsan Infotech-logos_transparent.png";
import nazsanlogowhite from "../assets/img/Nazsan Infotech-logos_white.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      {/* Start Footer 
    ============================================= */}
      <footer class="bg-dark text-light">
        <div class="footer-shape">
          <div class="item">
            <img src={imageshape7} alt="Shape" />
          </div>
          <div class="item">
            <img src={imageshape9} alt="Shape" />
          </div>
        </div>
        <div class="container">
          <div class="f-items relative pt-70 pb-120 pt-xs-0 pb-xs-50">
            <div class="row">
              <div class="col-lg-4 col-md-6 footer-item pr-50 pr-xs-15">
                <div class="f-item about">
                  <img class="logo" src={nazsanlogowhite} alt="Logo" />
                  <p style={{textAlign:"justify"}}>
                    Nazsan Infotech: Your go-to companion for IT solutions,
                    focused on boosting your success through creativity, skill,
                    and outstanding performance.
                  </p>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 footer-item" style={{textAlign:'left'}}>
                <div class="f-item link">
                  <h4 class="widget-title">Our Company</h4>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/#services">Servies</a>
                    </li>
                    <li>
                      <a href="/#about">About us</a>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Support Services</Link>
                    </li>
                    <li>
                      <Link to="/#services">Software Testing</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 footer-item" style={{textAlign:'left'}}>
                <div class="f-item link">
                  <h4 class="widget-title">Services</h4>
                  <ul>
                    <li>
                      <Link to="/contact-us">Software Development</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Firewall Development</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">
                        Network Design and Administration
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">
                        Security System Maintenance
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 footer-item" style={{textAlign:'left'}}>
                <h4 class="widget-title">Contact us</h4>
                <p>
                  Building No. R.25 , Second Floor, Rita Block Shakarpur,
                  Delhi-110092
                </p>
                <li>
                  <a href="mailto:nazsaninfotech@gmail.com">
                    nazsaninfotech@gmail.com
                  </a>
                </li>
                <li>
                  <a href="tel:+91 98 2167 4801">+91 85 2761 1670</a>
                </li>
                <ul class="footer-social"></ul>
              </div>
            </div>
          </div>
        </div>

        {/* Start Footer Bottom */}
        <div class="footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <p>
                  &copy; Copyright 2024{" "}
                  <a href="#">Nazsan Infotech Pvt. Ltd.</a> All Rights
                  Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
