import { useState } from "react";
import nazsanlogo from "../assets/img/Nazsan Infotech-logos_transparent.png";
import { Link } from "react-router-dom";
const Header = ()=>{
    const [isOpen, setIsOpen] = useState(false);

    const ulStyle = {
        height: "300px",
        transition: "height .3s all ease-in-out"
    }
    return(
        <header>
                <nav className="p-4 fixed-top">
                    <div className="heading-and-btn">
                        <button className="togle-btn" style={{ background: "transparent", border: "none" }} onClick={() => setIsOpen(!isOpen)}>
                            {isOpen ? <i className="close large icon"></i> : <i className="bars large icon"></i>}
                        </button>

                        <div className="heading m-0">
                            <img src={nazsanlogo} className="d-grid" alt="Nazsan Logo" width={"300px"} />
                        </div>
                    </div>


                    <div className="small-screen links-and-btn" style={isOpen ? ulStyle : {}}>
                        <div>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/#about">About</a></li>
                                <li><a href="/#services">Services</a></li>
                                {/* <li><a href="/contact-us">Contact</a></li> */}
                                <li><Link to="/contact-us">Contact</Link></li>
                            </ul>
                        </div>

                        <div style={{ alignItems: "flex-start", display: "flex" }}>
                            <Link className="ui button primary" to="/contact-us">Get Quote</Link>
                        </div>
                    </div>

                    <div className="large-screen links-and-btn">
                        <div>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/#about">About</a></li>
                                <li><a href="/#services">Services</a></li>
                                <li><Link to="/contact-us">Contact</Link></li>
                            </ul>
                        </div>

                        <div>
                        <Link className="ui button primary" to="/contact-us">Get Quote</Link>
                        </div>
                    </div>


                </nav>
            </header>
    )
}

export default Header;